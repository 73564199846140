import pageOne from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_01.jpg";
import pageTwo from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_02.jpg";
import pageThree from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_03.jpg";
import pageFour from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_04.jpg";
import pageFive from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_05.jpg";
import pageSix from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_06.jpg";
import pageSeven from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_07.jpg";
import pageEight from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_08.jpg";
import pageNine from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_09.jpg";
import pageTen from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_10.jpg";
import pageEleven from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_11.jpg";
import pageTwelve from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_12.jpg";
import pageThirteen from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_13.jpg";
import pageFourteen from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_14.jpg";
import pageFifteen from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_15.jpg";
import pageSixteen from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_16.jpg";
import pageSeventeen from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_17.jpg";
import pageEighteen from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_18.jpg";
import pageNineteen from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_19.jpg";
import pageTwenty from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_20.jpg";
import pageTwentyone from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_21.jpg";
import pageTwentytwo from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_22.jpg";
import pageTwentythree from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_23.jpg";
import pageTwentyfour from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_24.jpg";
import pageTwentyfive from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_25.jpg";
import pageTwentysix from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_26.jpg";
import pageTwentyseven from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_27.jpg";
import pageTwentyeight from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_28.jpg";
import pageTwentynine from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_29.jpg";
import pageThirty from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_30.jpg";
import pageThirtyone from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_31.jpg";
import pageThirtytwo from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_32.jpg";
import pageThirtythree from "../assets/GOL_jpg/GOL2_Comic_v22-single-pg-fmt_Page_33.jpg";

import pg4pn1 from "../assets/frames.jpg/GOL_STORY_FINAL4_Frame1.jpg"
import pg4pn2 from "../assets/frames.jpg/GOL_STORY_FINAL4_Frame2.jpg"
import pg4pn3 from "../assets/frames.jpg/GOL_STORY_FINAL4_Frame3.jpg"

import pg5pn1 from "../assets/frames.jpg/GOL_STORY_FINAL5_Frame1.jpg"
import pg5pn2 from "../assets/frames.jpg/GOL_STORY_FINAL5_Frame2.jpg"

import pg7pn1 from "../assets/frames.jpg/GOL_STORY_FINAL8_Frame1.jpg"
import pg7pn2 from "../assets/frames.jpg/GOL_STORY_FINAL8_Frame2.jpg"
import pg7pn3 from "../assets/frames.jpg/GOL_STORY_FINAL8_Frame3.jpg"

import pg8pn1 from "../assets/frames.jpg/GOL_STORY_FINAL9_Frame1.jpg"
import pg8pn2 from "../assets/frames.jpg/GOL_STORY_FINAL9_Frame2.jpg"

import pg9pn1 from "../assets/frames.jpg/GOL_STORY_FINAL10_Frame1.jpg"
import pg9pn2 from "../assets/frames.jpg/GOL_STORY_FINAL10_Frame2.jpg"
import pg9pn3 from "../assets/frames.jpg/GOL_STORY_FINAL10_Frame3.jpg"

import pg10pn1 from "../assets/frames.jpg/GOL_STORY_FINAL11_Frame1.jpg"
import pg10pn2 from "../assets/frames.jpg/GOL_STORY_FINAL11_Frame2.jpg"
import pg10pn3 from "../assets/frames.jpg/GOL_STORY_FINAL11_Frame3.jpg"

import pg12pn1 from "../assets/frames.jpg/GOL_STORY_FINAL13_Frame1.jpg";
import pg12pn2 from "../assets/frames.jpg/GOL_STORY_FINAL13_Frame2.jpg";

import pg13pn1 from "../assets/frames.jpg/GOL_STORY_FINAL14_Frame1.jpg"
import pg13pn2 from "../assets/frames.jpg/GOL_STORY_FINAL14_Frame2.jpg"

import pg15pn1 from "../assets/frames.jpg/GOL_STORY_FINAL16_Frame1.jpg"
import pg15pn2 from "../assets/frames.jpg/GOL_STORY_FINAL16_Frame2.jpg"
import pg15pn3 from "../assets/frames.jpg/GOL_STORY_FINAL16_Frame3.jpg"

import pg16pn1 from "../assets/frames.jpg/GOL_STORY_FINAL17_Frame1.jpg"
import pg16pn2 from "../assets/frames.jpg/GOL_STORY_FINAL17_Frame2.jpg"
import pg16pn3 from "../assets/frames.jpg/GOL_STORY_FINAL17_Frame3.jpg"

import pg18pn1 from "../assets/frames.jpg/GOL_STORY_FINAL20_Frame1.jpg"
import pg18pn2 from "../assets/frames.jpg/GOL_STORY_FINAL20_Frame2.jpg"
import pg18pn3 from "../assets/frames.jpg/GOL_STORY_FINAL20_Frame3.jpg"

import pg19pn1 from "../assets/frames.jpg/GOL_STORY_FINAL21_Frame1.jpg"
import pg19pn2 from "../assets/frames.jpg/GOL_STORY_FINAL21_Frame2.jpg"

import pg20pn1 from "../assets/frames.jpg/GOL_STORY_FINAL22_Frame1.jpg"
import pg20pn2 from "../assets/frames.jpg/GOL_STORY_FINAL22_Frame2.jpg"

import pg21pn1 from "../assets/frames.jpg/GOL_STORY_FINAL23_Frame1.jpg"
import pg21pn2 from "../assets/frames.jpg/GOL_STORY_FINAL23_Frame2.jpg"
import pg21pn3 from "../assets/frames.jpg/GOL_STORY_FINAL23_Frame3.jpg"
import pg21pn4 from "../assets/frames.jpg/GOL_STORY_FINAL23_Frame4.jpg"

import pg22pn1 from "../assets/frames.jpg/GOL_STORY_FINAL24_Frame1.jpg"
import pg22pn2 from "../assets/frames.jpg/GOL_STORY_FINAL24_Frame2.jpg"
import pg22pn3 from "../assets/frames.jpg/GOL_STORY_FINAL24_Frame3.jpg"

import pg23pn1 from "../assets/frames.jpg/GOL_STORY_FINAL25_Frame1.jpg"
import pg23pn2 from "../assets/frames.jpg/GOL_STORY_FINAL25_frame2.jpg"
import pg23pn3 from "../assets/frames.jpg/GOL_STORY_FINAL25_Frame3.jpg"

import pg24pn1 from "../assets/frames.jpg/GOL_STORY_FINAL26_Frame1.jpg"
import pg24pn2 from "../assets/frames.jpg/GOL_STORY_FINAL26_Frame2.jpg"

import pg25pn1 from "../assets/frames.jpg/GOL_STORY_FINAL27_Frame1.jpg"
import pg25pn2 from "../assets/frames.jpg/GOL_STORY_FINAL27_Frame2.jpg"
import pg25pn3 from "../assets/frames.jpg/GOL_STORY_FINAL27_Frame3.jpg"

import pg27pn1 from "../assets/frames.jpg/GOL_STORY_FINAL30_Frame1.jpg"
import pg27pn2 from "../assets/frames.jpg/GOL_STORY_FINAL30_Frame2.jpg"


import pg28pn1 from "../assets/frames.jpg/GOL_STORY_FINAL31_Frame1.jpg"
import pg28pn2 from "../assets/frames.jpg/GOL_STORY_FINAL31_Frame2.jpg"


import pg30pn1 from "../assets/frames.jpg/GOL_STORY_FINAL33_Frame1.jpg"
import pg30pn2 from "../assets/frames.jpg/GOL_STORY_FINAL33_Frame2.jpg"
import pg30pn3 from "../assets/frames.jpg/GOL_STORY_FINAL33_Frame3.jpg"


import pg31pn1 from "../assets/frames.jpg/GOL_STORY_FINAL34_Frame1.jpg"
import pg31pn2 from "../assets/frames.jpg/GOL_STORY_FINAL34_Frame2.jpg"
import pg31pn3 from "../assets/frames.jpg/GOL_STORY_FINAL34_Frame3.jpg"





const COMIC_PAGES =  [
    {
        pageNum: 1,
        img: pageOne,
        // panels: []
    },
    {
        pageNum: 2,
        img: pageTwo,
        // panels: []
    },
    {
        pageNum: 3,
        img: pageThree,
        // panels: []
    },
    {
        pageNum: 4,
        img: pageFour,
        panels: [
            {
                pageNum: 1,
                img: pg4pn1
            },
            {
                pageNum: 2,
                img: pg4pn2
            },            
            {
                pageNum: 3,
                img: pg4pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 5,
        img: pageFive,
        panels: [
            {
                pageNum: 1,
                img: pg5pn1
            },
            {
                pageNum: 2,
                img: pg5pn2
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 6,
        img: pageSix,
    },
    {
        pageNum: 7,
        img: pageSeven,
        panels: [
            {
                pageNum:1,
                img: pg7pn1
            },
            {
                pageNum:2,
                img: pg7pn2
            },
            {
                pageNum:3,
                img: pg7pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 8,
        img: pageEight,
        panels: [
            {
                pageNum:1,
                img: pg8pn1
            },
            {
                pageNum:2,
                img: pg8pn2
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 9,
        img: pageNine,
        panels: [
            {
                pageNum: 1,
                img: pg9pn1
            },
            {
                pageNum: 2,
                img: pg9pn2
            },            
            {
                pageNum: 3,
                img: pg9pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 10,
        img: pageTen,
        panels: [
            {
                pageNum: 1,
                img: pg10pn1
            },
            {
                pageNum: 2,
                img: pg10pn2
            },            
            {
                pageNum: 3,
                img: pg10pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 11,
        img: pageEleven,
    },
    {
        pageNum: 12,
        img: pageTwelve,
        panels: [
            {
                pageNum: 1,
                img: pg12pn1
            },
            {
                pageNum: 2,
                img: pg12pn2
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 13,
        img: pageThirteen,
        panels: [
            {
                pageNum: 1,
                img: pg13pn1
            },
            {
                pageNum: 2,
                img: pg13pn2
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 14,
        img: pageFourteen,
    },
    {
        pageNum: 15,
        img: pageFifteen,
        panels: [
            {
                pageNum: 1,
                img: pg15pn1
            },
            {
                pageNum: 2,
                img: pg15pn2
            },            
            {
                pageNum: 3,
                img: pg15pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 16,
        img: pageSixteen,
        panels: [
            {
                pageNum: 1,
                img: pg16pn1
            },
            {
                pageNum: 2,
                img: pg16pn2
            },            
            {
                pageNum: 3,
                img: pg16pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 17,
        img: pageSeventeen,
    },
    {
        pageNum: 18,
        img: pageEighteen,
        panels: [
            {
                pageNum: 1,
                img: pg18pn1
            },
            {
                pageNum: 2,
                img: pg18pn2
            },            
            {
                pageNum: 3,
                img: pg18pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 19,
        img: pageNineteen,
        panels: [
            {
                pageNum: 1,
                img: pg19pn1
            },
            {
                pageNum: 2,
                img: pg19pn2
            },            
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 20,
        img: pageTwenty,
        panels: [
            {
                pageNum: 1,
                img: pg20pn1
            },
            {
                pageNum: 2,
                img: pg20pn2
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 21,
        img: pageTwentyone,
        panels: [
            {
                pageNum: 1,
                img: pg21pn1
            },
            {
                pageNum: 2,
                img: pg21pn2
            },
            {
                pageNum: 3,
                img: pg21pn3
            },
            {
                pageNum: 4,
                img: pg21pn4
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 22,
        img: pageTwentytwo,
        panels: [
            {
                pageNum: 1,
                img: pg22pn1
            },
            {
                pageNum: 2,
                img: pg22pn2
            },            
            {
                pageNum: 3,
                img: pg22pn3
            },

            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 23,
        img: pageTwentythree,
        panels: [
            {
                pageNum: 1,
                img: pg23pn1
            },
            {
                pageNum: 2,
                img: pg23pn2
            },            
            {
                pageNum: 3,
                img: pg23pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 24,
        img: pageTwentyfour,
        panels: [
            {
                pageNum: 1,
                img: pg24pn1
            },
            {
                pageNum: 2,
                img: pg24pn2
            },            
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 25,
        img: pageTwentyfive,
        panels: [
            {
                pageNum: 1,
                img: pg25pn1
            },
            {
                pageNum: 2,
                img: pg25pn2
            },
            {
                pageNum: 3,
                img: pg25pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 26,
        img: pageTwentysix,
    },
    {
        pageNum: 27,
        img: pageTwentyseven,
        panels: [
            {
                pageNum: 1,
                img: pg27pn1
            },
            {
                pageNum: 2,
                img: pg27pn2
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 28,
        img: pageTwentyeight,
        panels: [
            {
                pageNum: 1,
                img: pg28pn1
            },
            {
                pageNum: 2,
                img: pg28pn2
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 29,
        img: pageTwentynine,
    },
    {
        pageNum: 30,
        img: pageThirty,
        panels: [
            {
                pageNum: 1,
                img: pg30pn1
            },
            {
                pageNum: 2,
                img: pg30pn2
            },
            {
                pageNum: 3,
                img: pg30pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 31,
        img: pageThirtyone,
        panels: [
            {
                pageNum: 1,
                img: pg31pn1
            },
            {
                pageNum: 2,
                img: pg31pn2
            },
            {
                pageNum: 3,
                img: pg31pn3
            },
            {
                pageNum: 100,
                img: 'no image'
            }
        ]
    },
    {
        pageNum: 32,
        img: pageThirtytwo,
    },
    {
        pageNum: 33,
        img: pageThirtythree,
    }
];

export default COMIC_PAGES;