import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination, Navigation, Keyboard } from 'swiper';

import './page.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './page.css'

const Page = ({ comic, handleToggle, togglePanel, setTogglePanel, setCurrentPage, currentPage}) => {



    const handleClick = event => {
        switch (event.detail) {
          case 1: {
            break;
          }
          case 2: {
            handleToggle(event.target.id)
            break;
          }
          default: {
            break;
          }
        }
      };

  return (
    
    <div className='container'>
        <Swiper
            pagination={{
                type: 'progressbar',
            }}
            keyboard={{
                enabled: true,
            }}
            autoHeight={true}
            navigation={true}
            modules={[Pagination, Navigation, Keyboard]}
            className='swiper-container'
            initialSlide={currentPage}
            slidesPerView={1}
            breakpoints={{
                300: {
                    spaceBetween: 200,
                }
            }}
        >
            {
                comic.map(page => (
                    <SwiperSlide key={`swiper-slide-${page.pageNum}`} >
                        <img onClick={handleClick} id={page.pageNum} key={page.pageNum} src={page.img} alt='comic-page'/>
                    </SwiperSlide>
                ))
            }
        </Swiper>
    </div>
  )
}
export default Page