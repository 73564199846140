import { Swiper, SwiperSlide } from 'swiper/react';

import { Pagination, Navigation, Keyboard } from 'swiper';



import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './panel.css'

const Panel = ({ comic, togglePanel, setTogglePanel, currentPage, setCurrentPage }) => {

  

    const handleToggle = () => {
        setTogglePanel(!togglePanel)
        console.log(togglePanel)
    }

    const handleClick = event => {
        console.log(event.detail);
        switch (event.detail) {
          case 1: {
            console.log('single click');
            break;
          }
          case 2: {
            console.log(event.target)
            handleToggle()
            break;
          }
          default: {
            break;
          }
        }
      };


  return (
    <div>
        <div className='panel-container'>
        <Swiper
            pagination={{
                type: 'progressbar',
            }}
            keyboard={{
                enabled: true,
            }}
            navigation={true}
            autoHeight={true}
            modules={[Pagination, Navigation, Keyboard]}
            className='panel-swiper-container'
            slidesPerView={1}
            onReachEnd={() => {
                handleToggle()
                ;
            }}
            centeredSlides={true}
            breakpoints={{
                300: {
                    spaceBetween: 200,
                }
            }}
        >
            {
                comic.map(page => (
                    <SwiperSlide className='panel-swiper-slide' key={`swiper-panel-slide${page.pageNum}`} >
                        <img onClick={handleClick} id={page.pageNum} key={page.pageNum} src={page.img} alt='comic-page'/>
                    </SwiperSlide>
                ))
            }
        </Swiper>
        </div>
        {/* <div>
            <button onClick={handleNext}>Next</button>
        </div> */}
    </div>
  )

}

export default Panel