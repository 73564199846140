import React, { useState } from "react";

import Page from "./components/comic-pages/page";
import Panel from "./components/comic-panels/panel";
import "./App.css";

import COMIC_PAGES from "./data/comic-data";


export default function App() {

  const [togglePanel, setTogglePanel] = useState(false)
  const [currentPage, setCurrentPage] = useState(0) 


  const handleToggle = (id) => {
    setCurrentPage(id)
    console.log(togglePanel)
    if (COMIC_PAGES[id -1].panels){
      setTogglePanel(!togglePanel)
    }
  }

  const handleToggleOnly = (id) => {
    setCurrentPage(id -1)
    if (COMIC_PAGES[id -1].panels){
      setTogglePanel(!togglePanel)
    }
  } 

  return (
    <div className="App">
    <div className="pagination-bar"/>

      {
        togglePanel === false 
          ? 
          <Page comic={COMIC_PAGES} currentPage={currentPage} setCurrentPage={setCurrentPage} togglePanel={togglePanel} handleToggle={handleToggle} setTogglePanel={setTogglePanel} />
          :
          <Panel comic={COMIC_PAGES[currentPage -1].panels} currentPage={currentPage} togglePanel={togglePanel} handleToggle={handleToggle} setTogglePanel={setTogglePanel} handleToggleOnly={handleToggleOnly} />
      }

    </div>
  );
}
          // <Page comic={COMIC_PAGES[currentPage -1].panels} currentPage={currentPage} setCurrentPage={setCurrentPage} togglePanel={togglePanel} setTogglePanel={setTogglePanel} />
